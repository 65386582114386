<template>
  <div class="container-fluid">
    <div class="row mb-5" v-if="showHeader">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
    </div>

    <purchases-delivery-view-details-purchases-delivery-item-list
      :purchasesDelivery="purchasesDelivery"
      :showHeader="showHeader"
      @purchasesDeliveryItemsUpdated="purchasesDeliveryItemsUpdated"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import PurchasesDeliveryViewDetailsPurchasesDeliveryItemList from "./PurchasesDeliveryViewDetailsPurchasesDeliveryItemList.vue";

export default {
  name: "purchases-delivery-view-details",

  components: {
    PurchasesDeliveryViewDetailsPurchasesDeliveryItemList,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    purchasesDelivery: {
      default: {},
    },
    showHeader: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    purchasesDeliveryItemsUpdated() {
      this.$emit("purchasesDeliveryItemsUpdated");
    },
  },

  mounted() {},

  watch: {},
};
</script>
