<template>
  <div>
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
      >
        <product-selector
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS) && showHeader
          "
          :filterOrganization="purchasesDelivery.organization.id"
          :filterWarehouse="getWarehouse()"
          @productChanged="(productId) => (selectedProduct = productId)"
          :filterIds="getPurchasesOrderProductsIds()"
        />
      </div>
      <el-table
        class="table-responsive align-items-center table-flush purchases-delivery-product-list"
        header-row-class-name="thead-light"
        :data="filteredPurchasesDeliveryItemsModel"
        type="expand"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="80px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterOrganization="purchasesDelivery.organization.id"
                :productObject="
                  purchasesDeliveryItemsModel[row.id].purchasesDeliverable
                "
                :filterWarehouse="getWarehouse()"
                :filterSupplier="
                  purchasesDelivery.issuer ? purchasesDelivery.issuer.id : null
                "
                :filterIds="getPurchasesOrderProductsIds()"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                @productChanged="
                  (productId, product) => {
                    modelProductChanged(
                      productId,
                      product,
                      purchasesDeliveryItemsModel[row.id]
                    );
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.purchasesDeliverable && row.id > 0"
              :object="row.purchasesDeliverable"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.EXPECTED_QUANTITY')"
          prop="quantity"
          min-width="40px"
          v-if="purchasesDelivery.status == DELIVERY_STATUS_VALIDATED"
        >
          <template v-slot="{ row }">
            <base-input
              v-if="purchasesDelivery.status == DELIVERY_STATUS_DRAFT"
              class="my-5"
              :placeholder="$t('COMMON.EXPECTED_QUANTITY')"
              v-model="purchasesDeliveryItemsModel[row.id].expected_quantity"
              input-classes="form-control-alternative"
            />
            <span v-if="purchasesDelivery.status != DELIVERY_STATUS_DRAFT">
              {{ purchasesDeliveryItemsModel[row.id].expected_quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            purchasesDelivery.status == DELIVERY_STATUS_VALIDATED
              ? $t('COMMON.DELIVERED_QUANTITY')
              : $t('COMMON.QUANTITY')
          "
          prop="quantity"
          min-width="40px"
        >
          <template v-slot="{ row }">
            <base-input
              v-if="purchasesDelivery.status == DELIVERY_STATUS_DRAFT"
              class="my-5"
              :placeholder="
                purchasesDelivery.status == DELIVERY_STATUS_VALIDATED
                  ? $t('COMMON.DELIVERED_QUANTITY')
                  : $t('COMMON.QUANTITY')
              "
              v-model="purchasesDeliveryItemsModel[row.id].quantity"
              input-classes="form-control-alternative"
            />
            <span v-if="purchasesDelivery.status != DELIVERY_STATUS_DRAFT">
              {{ purchasesDeliveryItemsModel[row.id].quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('COMMON.UNIT_OF_MEASURE_UNIT')">
          <template v-slot="{ row }">
            <object-link :object="row.unitOfMeasureUnit" />
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="20px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Delete"
              placement="top"
              v-if="
                $currentUserCan($permissions.PERM_DELETE_PRODUCTS) &&
                purchasesDelivery.status == DELIVERY_STATUS_DRAFT
              "
            >
              <a
                type="text"
                @click="deletePurchasesDeliveryItem(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div
        class="row px-3 mt-3"
        v-if="purchasesDelivery.status == DELIVERY_STATUS_DRAFT"
      >
        <base-button
          type="info"
          class="btn-sm mr-1 col-10"
          style="width: 100%"
          v-if="canAddLine()"
          @click="
            () => {
              addLine();
            }
          "
        >
          <i class="fas fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
        <base-button
          type="primary"
          class="btn-sm ml-1 col"
          style="width: 100%"
          @click="savePurchasesDeliveryItems()"
        >
          <i class="fas fa-save"></i>
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  DELIVERY_STATUS_DRAFT,
  DELIVERY_STATUS_VALIDATED,
} from "@/constants/deliveries";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductSelector from "@/components/ProductSelector.vue";

export default {
  components: {
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    purchasesDelivery: {
      type: Object,
      default: null,
      description: "purchasesDelivery",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredPurchasesDeliveryItemsModel: [],
      loading: false,
      selectedProduct: null,
      purchasesDeliveryItemsModel: {},
      deletedPurchasesDeliveryItems: {},
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_VALIDATED: DELIVERY_STATUS_VALIDATED,
    };
  },

  computed: {},

  watch: {
    purchasesDelivery: {
      handler: "refreshPurchasesDeliveryItems",
      immediate: true,
    },
    selectedProduct: {
      handler: "filterPurchasesDeliveryItems",
      immediate: true,
    },
  },

  mounted() {
    this.refreshPurchasesDeliveryItems();
  },

  methods: {
    getWarehouse() {
      if (this.purchasesDelivery.destinationWarehouse) {
        return this.purchasesDelivery.destinationWarehouse.id;
      }
      return null;
    },

    getPurchasesOrderProductsIds() {
      if (!this.purchasesDelivery.purchasesOrder.deliveryItemsState) {
        return null;
      }
      const remainingItemsIds = Object.values(
        this.purchasesDelivery.purchasesOrder.deliveryItemsState.remainingItems
      )
        .filter((item) => item.quantity > 0)
        .map((item) => item.product_id);
      if (remainingItemsIds.length > 0) {
        return remainingItemsIds;
      }
      return null;
    },

    modelProductChanged(productId, product, model) {
      if (product.warehouseProduct) {
        model.purchasesDeliverable = {
          type: "warehouse-products",
          id: product.warehouseProduct.id.toString(),
        };
        model.code =
          product.warehouseProduct.sku || product.sku || product.code;
      } else if (product.supplierProduct) {
        model.purchasesDeliverable = {
          type: "supplier-products",
          id: product.supplierProduct.id.toString(),
        };
        model.code = product.supplierProduct.sku || product.sku || product.code;
      } else {
        model.purchasesDeliverable.id = productId;
        model.code = product.code;
      }
      model.purchasesDeliverable.product_id = product.id;
    },

    canAddLine() {
      const deliveryItemsIds = Object.values(
        this.purchasesDeliveryItemsModel
      ).map((item) => item.purchasesDeliverable.product_id);
      if (!this.purchasesDelivery.purchasesOrder.deliveryItemsState) {
        return true;
      }
      const remainingItemsIds = Object.values(
        this.purchasesDelivery.purchasesOrder.deliveryItemsState.remainingItems
      ).filter((item) => !deliveryItemsIds.includes(item.product_id));
      return remainingItemsIds.length > 0;
    },

    async filterPurchasesDeliveryItems() {
      this.filteredPurchasesDeliveryItemsModel = Object.values(
        this.purchasesDeliveryItemsModel
      ).filter((item) => {
        if (item.id < 0) {
          return true;
        }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.purchasesDeliverable.id) {
            return false;
          }
        }
        return true;
      });
    },

    async deletePurchasesDeliveryItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
      }

      this.deletedPurchasesDeliveryItems[row.id] = _.cloneDeep(row);
      delete this.purchasesDeliveryItemsModel[row.id];
      this.filterPurchasesDeliveryItems();
    },

    async savePurchasesDeliveryItems() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        for (const line of Object.values(this.deletedPurchasesDeliveryItems)) {
          if (line.id > 0) {
            await this.$store.dispatch(
              "purchasesDeliveryItems/destroy",
              line.id
            );
          }
        }
        this.deletedPurchasesDeliveryItems = {};

        for (const line of Object.values(this.purchasesDeliveryItemsModel)) {
          const purchasesDeliveryItemData = _.cloneDeep(line);
          purchasesDeliveryItemData.expected_quantity =
            purchasesDeliveryItemData.quantity;
          if (line.id > 0) {
            await this.$store.dispatch(
              "purchasesDeliveryItems/update",
              purchasesDeliveryItemData
            );
          } else {
            if (purchasesDeliveryItemData.purchasesDeliverable.id) {
              delete purchasesDeliveryItemData.id;
              await this.$store.dispatch(
                "purchasesDeliveryItems/add",
                purchasesDeliveryItemData
              );
            }
          }
        }
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCTS_SAVED"),
        });
        this.$emit("purchasesDeliveryItemsUpdated");
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    addLine() {
      let newLinesIds = Object.values(this.purchasesDeliveryItemsModel)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newPurchasesDeliveryItem = {
        type: "purchases-delivery-items",
        id: newLineId,
        quantity: 0,
        relationshipNames: [
          "purchasesDeliverable",
          "purchasesDelivery",
          "unitOfMeasureUnit",
        ],
        purchasesDeliverable: {
          type: "products",
          id: null,
        },
        purchasesDelivery: {
          type: "purchases-deliveries",
          id: this.purchasesDelivery.id,
        },
        unitOfMeasureUnit: {
          type: "sales-deliveries",
          id: null,
        },
      };
      this.purchasesDeliveryItemsModel[newLineId] = _.cloneDeep(
        newPurchasesDeliveryItem
      );
      this.filterPurchasesDeliveryItems();
    },

    refreshPurchasesDeliveryItems() {
      const purchasesDeliveryItemsModelData = {};
      for (const item of this.purchasesDelivery.items) {
        purchasesDeliveryItemsModelData[item.id] = {
          type: "purchases-delivery-items",
          id: item.id,
          quantity: item.quantity,
          expected_quantity: item.expected_quantity,
          relationshipNames: [
            "purchasesDeliverable",
            "purchasesDelivery",
            "unitOfMeasureUnit",
          ],
          purchasesDeliverable: {
            type: item.purchasesDeliverable.type,
            id: item.purchasesDeliverable.id,
            name: item.purchasesDeliverable.name,
            product_id: item.purchasesDeliverable.product_id,
          },
          purchasesDelivery: {
            type: "purchases-deliveries",
            id: item.purchasesDelivery.id,
          },
          unitOfMeasureUnit: item.unitOfMeasureUnit,
        };
      }
      this.purchasesDeliveryItemsModel = purchasesDeliveryItemsModelData;
      this.filterPurchasesDeliveryItems();
    },
  },
};
</script>
<style>
.purchases-delivery-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.purchases-delivery-product-list
  .form-group.taxes-selector
  .remove-taxes-button {
  display: flex;
  align-items: center;
}
</style>
