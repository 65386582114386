var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PRODUCTS) && _vm.showHeader
        )?_c('product-selector',{attrs:{"filterOrganization":_vm.purchasesDelivery.organization.id,"filterWarehouse":_vm.getWarehouse(),"filterIds":_vm.getPurchasesOrderProductsIds()},on:{"productChanged":(productId) => (_vm.selectedProduct = productId)}}):_vm._e()],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush purchases-delivery-product-list",attrs:{"header-row-class-name":"thead-light","data":_vm.filteredPurchasesDeliveryItemsModel,"type":"expand"}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PRODUCT'),"prop":"product.name","min-width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.id < 0)?_c('base-input',[_c('product-selector',{attrs:{"filterOrganization":_vm.purchasesDelivery.organization.id,"productObject":_vm.purchasesDeliveryItemsModel[row.id].purchasesDeliverable,"filterWarehouse":_vm.getWarehouse(),"filterSupplier":_vm.purchasesDelivery.issuer ? _vm.purchasesDelivery.issuer.id : null,"filterIds":_vm.getPurchasesOrderProductsIds(),"allowNone":false,"showAll":false,"disabled":row.id > 0},on:{"productChanged":(productId, product) => {
                  _vm.modelProductChanged(
                    productId,
                    product,
                    _vm.purchasesDeliveryItemsModel[row.id]
                  );
                }}})],1):_vm._e(),(row.purchasesDeliverable && row.id > 0)?_c('object-link',{attrs:{"object":row.purchasesDeliverable}}):_vm._e()]}}])}),(_vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_VALIDATED)?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.EXPECTED_QUANTITY'),"prop":"quantity","min-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_DRAFT)?_c('base-input',{staticClass:"my-5",attrs:{"placeholder":_vm.$t('COMMON.EXPECTED_QUANTITY'),"input-classes":"form-control-alternative"},model:{value:(_vm.purchasesDeliveryItemsModel[row.id].expected_quantity),callback:function ($$v) {_vm.$set(_vm.purchasesDeliveryItemsModel[row.id], "expected_quantity", $$v)},expression:"purchasesDeliveryItemsModel[row.id].expected_quantity"}}):_vm._e(),(_vm.purchasesDelivery.status != _vm.DELIVERY_STATUS_DRAFT)?_c('span',[_vm._v(" "+_vm._s(_vm.purchasesDeliveryItemsModel[row.id].expected_quantity)+" ")]):_vm._e()]}}],null,false,4243157691)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_VALIDATED
            ? _vm.$t('COMMON.DELIVERED_QUANTITY')
            : _vm.$t('COMMON.QUANTITY'),"prop":"quantity","min-width":"40px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_DRAFT)?_c('base-input',{staticClass:"my-5",attrs:{"placeholder":_vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_VALIDATED
                ? _vm.$t('COMMON.DELIVERED_QUANTITY')
                : _vm.$t('COMMON.QUANTITY'),"input-classes":"form-control-alternative"},model:{value:(_vm.purchasesDeliveryItemsModel[row.id].quantity),callback:function ($$v) {_vm.$set(_vm.purchasesDeliveryItemsModel[row.id], "quantity", $$v)},expression:"purchasesDeliveryItemsModel[row.id].quantity"}}):_vm._e(),(_vm.purchasesDelivery.status != _vm.DELIVERY_STATUS_DRAFT)?_c('span',[_vm._v(" "+_vm._s(_vm.purchasesDeliveryItemsModel[row.id].quantity)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.UNIT_OF_MEASURE_UNIT')},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.unitOfMeasureUnit}})]}}])}),_c('el-table-column',{attrs:{"align":"center","min-width":"20px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[(
              _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_PRODUCTS) &&
              _vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_DRAFT
            )?_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deletePurchasesDeliveryItem(row)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)}}])})],1),(_vm.purchasesDelivery.status == _vm.DELIVERY_STATUS_DRAFT)?_c('div',{staticClass:"row px-3 mt-3"},[(_vm.canAddLine())?_c('base-button',{staticClass:"btn-sm mr-1 col-10",staticStyle:{"width":"100%"},attrs:{"type":"info"},on:{"click":() => {
            _vm.addLine();
          }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("PRODUCTS.ADD_PRODUCT"))+" ")]):_vm._e(),_c('base-button',{staticClass:"btn-sm ml-1 col",staticStyle:{"width":"100%"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.savePurchasesDeliveryItems()}}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.SAVE"))+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }